function timeScale(width, visible_candles = 280) {
  const alpha = width / visible_candles;

  const calc_px = (candle_index) => {
    return candle_index * alpha;
  };

  return calc_px;
}

export default timeScale;
