function Crosshair(context, Mouse, width, height, options) {
  const {
    crosshairType,
    verticalLineEnabled,
    horizontalLineEnabled,
    styles: {
      verticalLineStyle,
      verticalLineColor,
      verticalLineWidth,

      horizontalLineStyle,
      horizontalLineColor,
      horizontalLineWidth,

      pointerSize,
      pointerColor,
      pointerBorderColor,
      pointerBorderWidth
    },
  } = options;

  if (verticalLineEnabled) {
    context.beginPath();
    // vertical styles
    context.lineWidth = verticalLineWidth;
    context.strokeStyle = verticalLineColor;
    context.setLineDash([]);
    verticalLineStyle == 'dashed' && context.setLineDash([5, 6]);
    verticalLineStyle == 'dotted' && context.setLineDash([1, 3]);
    // vertical draw
    context.moveTo(Mouse.x, 0);
    context.lineTo(Mouse.x, height);
    // draw
    context.stroke();
  }

  if (horizontalLineEnabled) {
    context.beginPath();
    // horizontal styles
    context.lineWidth = horizontalLineWidth;
    context.strokeStyle = horizontalLineColor;
    context.setLineDash([]);
    horizontalLineStyle == 'dashed' && context.setLineDash([5, 6]);
    horizontalLineStyle == 'dotted' && context.setLineDash([1, 3]);

    // vertical draw
    context.moveTo(0, Mouse.y);
    context.lineTo(width, Mouse.y);
    // draw
    context.stroke();
  }

  // point styles
  if (crosshairType == 'pointer') {
    context.beginPath();
    context.fillStyle = pointerColor;
    context.arc(Mouse.x, Mouse.y, pointerSize, 0, 10);
    context.fill();
  }


}

export default Crosshair;
