import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  x: 0,
  y: 0,
};

const translateSlice = createSlice({
  name: 'translate',
  initialState,
  reducers: {
    updateTranslate: (state, { payload: { x, y } }) => {
      state.x = x;
      state.y = y;
    },
  },
});

export const { updateTranslate } = translateSlice.actions;
export default translateSlice.reducer;
