/**
 *
 * @param {context} ctx
 * @param {timeScale} timeScale
 * @param {priceScale} priceScale
 * @param {object} options
 * @param {array} data
 */
const CandlestickChart = (ctx, timeScale, priceScale, options, data) => {
  const {
    style: {
      up_candle_body,
      up_candle_border,
      up_candle_wick,
      down_candle_body,
      down_candle_border,
      down_candle_wick,
    },
    wickEnabled = true,
    bodyEnabled = true,
    borderEnabled = true,
  } = options;

  data.map((candle, index) => {
    // generate candle pixels positions
    const time_px = Math.round(timeScale(index));
    const open_px = Math.round(priceScale(candle.open));
    const close_px = Math.round(priceScale(candle.close));
    const high_px = Math.round(priceScale(candle.high));
    const low_px = Math.round(priceScale(candle.low));

    // get candle center pixels positon
    const candle_center_px = time_px + bodyWidth(data.length) / 2;

    // draw wicks
    if (wickEnabled) {
      // set wick styles
      if (candle.close > candle.open) {
        ctx.strokeStyle = up_candle_wick;
      } else {
        ctx.strokeStyle = down_candle_wick;
      }
      // begin path
      ctx.beginPath();

      // draw upside wick
      ctx.moveTo(candle_center_px, candle.close > candle.open ? close_px : open_px);
      ctx.lineTo(candle_center_px, high_px);

      // draw downside wick
      ctx.moveTo(candle_center_px, candle.close > candle.open ? open_px : close_px);
      ctx.lineTo(candle_center_px, low_px);

      // stroke
      ctx.closePath();
      ctx.stroke();
    }

    // begin candle body
    ctx.beginPath();

    // set candle styles
    ctx.lineWidth = 1;
    if (candle.close > candle.open) {
      ctx.strokeStyle = up_candle_border;
      ctx.fillStyle = up_candle_body;
    } else {
      ctx.strokeStyle = down_candle_border;
      ctx.fillStyle = down_candle_body;
    }

    // draw candle body
    ctx.rect(time_px, open_px, bodyWidth(data.length), Math.round(close_px - open_px));

    // draw all
    ctx.closePath();
    bodyEnabled && ctx.fill();
    borderEnabled && ctx.stroke();
  });
};

const bodyWidth = (dataPoints) => {
  // set candle width based on visible data
  let width = 6;

  if (dataPoints <= 10) {
    width = 10;
  } else if (dataPoints <= 50) {
    width = 8;
  } else if (dataPoints <= 100) {
    width = 6;
  } else if (dataPoints <= 200) {
    width = 4;
  } else if (dataPoints <= 300) {
    width = 2;
  } else {
    width = 0;
  }
  return width;
};

export default CandlestickChart;
