import { configureStore } from '@reduxjs/toolkit';

import mouseReducer from './Mouse';
import translateReducer from './Translate';

const store = configureStore({
  reducer: {
    mouse: mouseReducer,
    translate: translateReducer,
  },
});

export default store;
