// default line chart
export const line_chart_default = {
  styles: { lineWidth: 0.4, lineColor: 'blue', pointSize: 0 },
  sourceType: 'close',
  chartType: 'simple',
};

// default candlestick chart
export const candlestick_chart_default = {
  style: {
    up_candle_body: 'blue',
    up_candle_border: 'black',
    up_candle_wick: 'black',
    down_candle_body: 'red',
    down_candle_border: 'black',
    down_candle_wick: 'black',
  },
  wickEnabled: true,
  bodyEnabled: true,
  borderEnabled: true,
};

// default croshair
export const croshair_default = {
  crosshairType: 'pointer',
  verticalLineEnabled: true,
  horizontalLineEnabled: true,
  styles: {
    verticalLineStyle: 'solid',
    verticalLineColor: 'lightgray',
    verticalLineWidth: 1,

    horizontalLineStyle: 'solid',
    horizontalLineColor: 'lightgray',
    horizontalLineWidth: 1,

    pointerSize: 1.5,
    pointerColor: 'gray',
    pointerBorderColor: 'trasparent',
    pointerBorderWidth: 0
  },
};
