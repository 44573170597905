import { useEffect, useState } from 'react';

import { ChartPane, ControlPane } from 'pane';
import { timeScale, priceScale } from 'scales';
import { PriceAxis, TimeAxis } from 'axis';
import { mockData } from 'assets';

function Chart() {
  const width = 1800;
  const height = 500;

  const min = Math.min(...mockData.map((obj) => obj.low));
  const max = Math.max(...mockData.map((obj) => obj.high));

  const TimeScale = timeScale(width, 300);
  const PriceScale = priceScale({
    domainStart: min,
    domainEnd: max,
    rangeStart: height,
    rangeEnd: 0,
  });

  const getPrice = (y) => {
    const diffprice = max - min;
    const alpha = diffprice / height;
    return parseFloat(max - y * alpha).toFixed(2);
  };

  const [Mouse, setMouse] = useState({ x: 0, y: 0 });
  const [Translate, setTranslate] = useState({ x: 0, y: 0 });


  useEffect(() => {}, []);

  return (
    <tr>
      <ChartPane
        width={width}
        height={height}
        timeScale={TimeScale}
        priceScale={PriceScale}
        data={mockData}
        Mouse={Mouse}
        Translate={Translate}
      />
      <ControlPane
        width={width}
        height={height}
        timeScale={TimeScale}
        priceScale={PriceScale}
        data={mockData}
        Mouse={Mouse}
        Translate={Translate}
        setMouse={setMouse}
        setTranslate={setTranslate}
        getPrice={getPrice}
      />
      <PriceAxis
        offsetLeft={width}
        height={height}
        priceScale={PriceScale}
        Mouse={Mouse}
        Translate={Translate}
        data={mockData}
        getPrice={getPrice}
      />
      <TimeAxis
        offsetTop={height}
        width={width}
        timeScale={TimeScale}
        Mouse={Mouse}
        Translate={Translate}
        data={mockData}
      />
    </tr>
  );
}

export default Chart;
