import { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { setupScreen, unixTimeParse } from '../utils';
const TimeAxis = ({ width, offsetTop, Xscale, data, Translate }) => {
  const height = 20;
  const canvasRef = useRef(null);
  // const Translate = useSelector(state => state.translate);
  const draw = (context) => {
    context.beginPath();
    context.font = '12px Arial';

    for (let index = 0; index < 80; index++) {
      context.fillText(
        unixTimeParse(data[index].time),
        Math.round(index * 100),
        Math.round(height - 5)
      );
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    setupScreen(canvas, context, height, width);
    context.translate(Translate.x, 0);
    draw(context);
  }, [Translate]);

  return (
    <canvas
      // width={width}
      // height={height}
      className="time-axis"
      ref={canvasRef}
      style={{
        // width,
        // height,
        position: 'absolute',
        top: offsetTop,
        borderTopStyle: 'solid',
        borderWidth: 1,
        borderColor: 'gray',
      }}
    />
  );
};

export default memo(TimeAxis);
