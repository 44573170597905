function priceScale({ domainStart, domainEnd, rangeStart, rangeEnd }) {
  const rangeDiff = rangeEnd - rangeStart;
  const domainDiff = domainEnd - domainStart;

  function calc_px(value) {
    return rangeStart + rangeDiff * ((value - domainStart) / domainDiff);
  }

  return calc_px;
}

export default priceScale;
