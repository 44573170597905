import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  x: 0,
  y: 0,
};

const mouseSlice = createSlice({
  name: 'mouse',
  initialState,
  reducers: {
    updatePosition: (state, { payload: { x, y } }) => {
      state.x = x;
      state.y = y;
    },
  },
});

export const { updatePosition } = mouseSlice.actions;
export default mouseSlice.reducer;
