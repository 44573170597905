import { memo, useEffect, useRef } from 'react';
import { setupScreen } from 'utils';
import { candlestickChart, lineChart } from 'chart_types';
import { candlestick_chart_default } from 'defaults';
// import { useSelector } from 'react-redux';
/**
 * @param {number} width
 * @param {number} height
 * @param {function} timeScale
 * @param {function} priceScale
 * @param {array} data
 * @returns {chart_pane}
 */

const ChartPane = ({ width, height, timeScale, priceScale, data, Translate }) => {
  const canvasRef = useRef(null);
  // const Translate = useSelector(state => state.translate)

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    setupScreen(canvas, context, height, width);

    context.translate(Translate.x, Translate.y);
    

    draw(context);
  }, [Translate]);

  const draw = (context) => {
    candlestickChart(context, timeScale, priceScale, candlestick_chart_default, data);
  };

  return (
    <canvas className="chart_pane" ref={canvasRef} style={{ zIndex: 0, position: 'absolute' }} />
  );
};

export default memo(ChartPane);
