import { useEffect, useState, useRef } from 'react';
import { setupScreen } from 'utils';
// import { updatePosition } from 'store/Mouse';
// import { updateTranslate } from 'store/Translate';
// import { useDispatch, useSelector } from 'react-redux';
import { Crosshair } from 'chart_base';
import { croshair_default } from 'defaults';
import { memo } from 'react';

const ControlPane = ({
  width,
  height,
  Mouse,
  Translate,
  setMouse,
  setTranslate,
  getPrice,
}) => {
  const canvasRef = useRef(null);
  const [Pan, setPan] = useState(false);
  const [InitPanSize, setInitPanSize] = useState({});
  const [StartSize, setStartSize] = useState({});
  // const [Mouse, setMouse] = useState({ x: 0, y: 0 });
  // const dispatch = useDispatch();
  // const Mouse = useSelector((state) => state.mouse);
  // const Translate = useSelector((state) => state.translate);

  const draw = (context) => {
    Crosshair(context, Mouse, width, height, croshair_default);

    // context.fillRect(Mouse.x + 10, Mouse.y - 25, 35, 20);

    context.font = '11px Ubuntu';
    context.fillStyle = 'red';
    context.fillText(getPrice(Mouse.y), Mouse.x + 10, Mouse.y - 10, 50);

    // const min = Math.min(...data.map((obj) => obj.low));
    // const max = Math.max(...data.map((obj) => obj.high));

    // const getPrice = (y) => {
    //   const diffprice = max - min;
    //   const alpha = diffprice / height;
    //   return parseFloat(max - y * alpha).toFixed(3);
    // };

    // context.beginPath();
    // const lastcandle = data[data.length - 1];
    // context.moveTo(
    //   Math.round(timeScale(data.length - 1)),
    //   Math.round(priceScale(lastcandle.close))
    // );
    // context.lineTo(width, Math.round(priceScale(lastcandle.close)));
    // context.stroke();
  };

  // document.removeEventListener('mousemove');
  document.addEventListener('mousemove', (event) => onMouseMove(event));

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    setupScreen(canvas, context, height, width);

    draw(context);
    // console.log(Translate);

    // if (!Pan) return;
    // const dragedX = Mouse.x - InitPanSize.x;
    // const dragedY = Mouse.y - InitPanSize.y;
    // dispatch(updateTranslate({ x: dragedX, y: dragedY }));

    if (Pan) calc();
  }, [Mouse]);

  const calc = () => {
    const dragedX = Mouse.x - InitPanSize.x;
    const dragedY = Mouse.y - InitPanSize.y;

    setTranslate({ x: StartSize.x + dragedX, y: StartSize.y + dragedY });
    //dispatch(updateTranslate({ x: StartSize.x + dragedX, y: StartSize.y + dragedY }));

    // console.log('dragedX', dragedX, 'dragedY', dragedY);
  };

  const onMouseMove = ({ clientX, clientY }) => {
    // dispatch(updatePosition({ x: Math.round(clientX), y: Math.round(clientY) }));
    setMouse({ x: Math.round(clientX), y: Math.round(clientY) });
  };

  const onMouseDown = ({ clientX, clientY }) => {
    setPan(true);
    setInitPanSize({ x: clientX, y: clientY });
    setStartSize(Translate);
  };

  const onMouseUp = ({ clientX, clientY }) => {
    setPan(false);
    // const dragedX = Mouse.x - InitPanSize.x;
    // const dragedY = Mouse.y - InitPanSize.y;

    // const finalX = Translate.x + dragedX;
    // const finalY = Translate.y + dragedY;

    // dispatch(updateTranslate({ x: finalX, y: finalY }));
  };

  return (
    <canvas
      className="control_pane"
      onMouseDown={onMouseDown}
      // onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      ref={canvasRef}
      style={{ zIndex: 1, position: 'absolute', cursor: 'pointer' }}
    />
  );
};

export default memo(ControlPane);
