import { useEffect, useRef, memo } from 'react';
import { setupScreen } from '../utils';
import { useSelector } from 'react-redux';
/**
 * @param {number} height
 * @param {number} offsetLeft
 * @param {function} priceScale
 * @param {array} data
 * @returns PriceAxis
 */
const PriceAxis = ({ height, offsetLeft, priceScale, Translate, Mouse, getPrice, data }) => {
  const width = 50;
  const canvasRef = useRef(null);
  // const Mouse = useSelector(state => state.mouse);
  // const Translate = useSelector(state => state.translate);
  const min = Math.min(...data.map((obj) => obj.low));
  const max = Math.max(...data.map((obj) => obj.high));

  // const getPrice = (y) => {
  //   const diffprice = max - min;
  //   const alpha = diffprice / height;
  //   return parseFloat(max - y * alpha).toFixed(2);
  // };
  const draw = (context) => {
    let lowest_price = Math.round(min) - 100;

    context.font = '12px Arial';
    context.textBaseline = 'middle';

    for (let index = -100; index < 100; index++) {
      context.fillText(
        parseFloat(lowest_price).toFixed(3),
        5,
        Math.round(priceScale(lowest_price))
      );
      lowest_price = lowest_price + 1;
    }

    context.fillStyle = 'black';
    context.font = '13px Ubuntu';

    context.lineWidth = 5;
    context.fillRect(-0.5, Mouse.y - 10 - Translate.y, 50, 20);
    context.fillStyle = 'white';

    context.fillText(getPrice(Mouse.y), 5, Mouse.y - Translate.y, 50);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    setupScreen(canvas, context, height, width);

    context.translate(0, Translate.y);

    draw(context);
  }, [Mouse]);

  return (
    <canvas
      className="price-axis"
      ref={canvasRef}
      style={{
        position: 'absolute',
        left: offsetLeft,
        borderLeftStyle: 'solid',
        borderWidth: 1,
        borderColor: 'gray',
      }}
    />
  );
};

export default memo(PriceAxis);
