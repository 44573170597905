import React from 'react';
import ReactDOM from 'react-dom/client';

// providers
import { MantineProvider } from '@mantine/core';
import { Provider } from 'react-redux';

// providers data
import store from './store';

// core component
import Chart from './Chart';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <MantineProvider withGlobalStyles withNormalizeCSS theme={{ colorScheme: 'light' }}>
      <Chart />
    </MantineProvider>
  </Provider>
);
