const setupScreen = (canvas, context, height, width) => {
  // get device pixel ration
  const devicePixelRatio = window.devicePixelRatio;

  // pixrl check
  if (devicePixelRatio <= 1) {
    // for normal displays
    canvas.width = width;
    canvas.height = height;
    context.translate(devicePixelRatio * 0.5, devicePixelRatio * 0.5);
  } else {
    // for retina displays
    canvas.width = Math.floor(width * devicePixelRatio);
    canvas.height = Math.floor(height * devicePixelRatio);
    context.scale(devicePixelRatio, devicePixelRatio);
  }
};

export default setupScreen;
